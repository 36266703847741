.item {
  .pagination {
    display: none;
  }
}

.item__wrapper {
  display: flex;
  margin-bottom: 30px;
  align-items: flex-start;
}

.item__photo {
  flex: 0 0 50%;
  margin-right: 5%;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  position: relative;

  .gallery-top {
    height: 370px;
    border-bottom: 1px solid #dfdfdf;

    .swiper-slide {
      position: relative;
      text-align: center;
    }

    .swiper-pagination {
      display: none;
    }

    img {
      height: 100%;
    }
  }

  .gallery-thumbs {
    height: 80px;

    .swiper-slide {
      opacity: 0.5;
      text-align: center;

      img {
        height: 100%;
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background: none;
      width: 6px;
      height: 10px;
      margin-top: -10px !important;
    }
  }
}

.item__info {
  flex: 0 0 45%;
}

.item__name {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: #0a0a0a;
  padding: 25px 0 15px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 24px;
  margin-top: 0;
}

.item__spec-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.item__availability {
  display: flex;
  font-size: 12px;
  line-height: 30px;
  align-items: center;
}

.item__availability-tag {
  height: 30px;
  background-color: #30a780;
  margin-right: 10px;
  color: #ffffff;
  padding: 0 10px;
}

.item__availability-link {
  text-decoration: underline;
  color: #686868;

  &:hover {
    text-decoration: none;
  }
}

.item__info-span {
  font-size: 14px;

  &:nth-child(even) {
    color: #686868;
  }

  &:nth-child(odd) {
    color: #0a0a0a;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.item__size {
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #686868;
}

.item__counter {
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #686868;
}

.item__size-title,
.item__counter-title {
  width: 100px;
}

.item__price {
  margin: 10px 0;
  font-size: 24px;
  font-weight: 700;
  color: #0a0a0a;

  &--old {
    font-size: 18px;
    color: #f45958;
    text-decoration: line-through;
  }
}

.item__button-group {
  display: flex;

  .button {
    width: 170px;
    margin-right: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;

    &--blue {
      font-weight: 700;
    }
  }
}

.item__toggle-list {
  margin: 0;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  padding: 16px 0;
}

.item__toggle-item {
  font-size: 14px;
  font-weight: 500;
  color: #878787;
  margin-right: 32px;

  &--active, &:hover {
    color: #0a0a0a;
    text-decoration: none;
  }
}

.item__text {
  padding: 13px 0 55px;
  max-width: 741px;

  &--table {
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  .item {
    .pagination {
      display: flex;
    }
  }

  .item__photo  {
    .gallery-top {
      height: 210px;
    }

    .gallery-thumbs {
      height: 60px;
    }
  }

  .item__button-group {
    .button {
      width: 157px;
      margin-right: 10px;
    }
  }

  .item__availability {
    margin-bottom: 10px;
  }


  .item__spec-wrapper {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .item__wrapper {
    display: block;
  }

  .item__photo {
    margin-right: 0;
    border-radius: 0;

    .gallery-top {
      height: 230px;

      img {
        height: 95%;
      }

      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background-color: #d8d8d8;
        opacity: 1;
        margin: 0 10px !important;
      }

      .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background: radial-gradient(circle, #000000 0%, #000000 40%, #ffffff 40%, #ffffff 100%);
        border: 1px solid #000000;
      }
    }

    .gallery-thumbs {
      display: none;
    }
  }

  .item__name {
    font-size: 20px;
    line-height: 24px;
  }

  .item__size, .item__counter {
    flex: 0 0 50%;
    display: block;
  }

  .item__size-title, .item__counter-title {
    margin-bottom: 5px;
  }

  .item__select-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .select {
      width: 90%;
    }
  }

  .item__button-group {
    justify-content: space-between;

    .button {
      flex: 0 0 45%;
    }
  }

  .item__toggle-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .item__toggle-item {
    flex: 0 0 25%;
    margin-right: 0;
    text-align: center;
  }

  .item__text {
    padding: 0;
  }
}