.popup {
  background-color: #fff;
  z-index: 10;
  overflow-y: auto;
  padding: 15px;
  position: relative;
}

.popup__close {
  font-size: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  padding: 0;
  border: none;
}

.popup__close-svg {
  width: 16px;
  height: 16px;
}

.popup__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: #45443c;
  text-align: center;
  margin: 0;
}

.popup-callback__text {
  opacity: 0.6;
  font-size: 12px;
  line-height: 14px;
  color: #45443c;
  margin: 0 0 15px;
  text-align: center;
}

.popup-callback__input {
  width: 100%;
  height: 50px;
}

.popup-callback__label {
  padding-left: 0;
  margin-bottom: 10px;
  display: inline-block;
}

.popup-callback__item {
  &:not(:first-child) {
    margin-top: 15px;
  }

  .label-default {
    font-size: 12px;
    font-weight: 700;
    color: #a1a9b3;
    text-transform: uppercase;
    width: 100%;
  }

  .label-check {
    display: block;
    font-size: 11px;
    line-height: 20px;
    color: #212121;
    padding-left: 25px;
    position: relative;

    a {
      color: #1882f7;
    }
  }

  .button {
    width: 100%;
  }
}

.popup-callback {
  width: 440px;
}

@media screen and (max-width: 520px) {
  .popup-callback {
    width: auto;
  }
}