.nav {
  box-sizing: border-box;
}

.nav__list {
  height: 50px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}

.nav__item {
  flex: 0 0 167px;

  &--tablet {
    display: none;
  }

  &--cart {
    background: url('../img/icons/cart.svg') 20px 50% no-repeat;

    .nav__link {
      box-sizing: border-box;
      line-height: normal;
      font-size: 14px;
      font-weight: 700;
      color: #000000;
      text-transform: none;
      text-align: left;
      padding-left: 55px;
      padding-top: 8px;

      &::after {
        content: none;
      }
    }
  }

  &:hover,
  &--active {
    box-shadow: inset 0 10px 20px 0 rgba(40, 62, 85, 0.1);
    background-color: #f6f8fa;

    .nav__submenu {
      display: block;
    }
  }
}

.nav__span {
  opacity: 0.6;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-decoration: underline;
}

.nav__link {
  display: block;
  height: 100%;
  box-sizing: border-box;
  line-height: 50px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: normal;
  text-align: center;
  color: #686868;
  text-transform: uppercase;

  &::after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 10px;
    background: url('../img/icons/arrow-down.svg') 50% 50% no-repeat;
    margin-left: 9px;
  }

  &--index, &--cart {
    &::after {
      content: none;
    }
  }
}

.nav__submenu {
  position: absolute;
  display: none;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  list-style: none;
  margin: 0;
  padding: 20px;
  z-index: 1000;
  min-width: 168px;
  box-sizing: border-box;
}

.nav__submenu-item {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.nav__submenu-link {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #686868;
}

@media screen and (max-width: 768px) {
  .nav__item {
    flex-basis: 108px;

    &--desktop {
      display: none;
    }

    &--tablet {
      display: block;
      flex-basis: 30px;
    }
  }

  .nav__link {
    font-size: 12px;

    &--cart {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }

  .nav__label {
    display: block;
    font-size: 8px;
    height: 14px;
    width: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background-color: #f45958;
    position: absolute;
    top: 10px;
    right: -2px;
  }
}

@media screen and (max-width: 520px) {
  .nav {
    display: none;
  }
}