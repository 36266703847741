.banners {
  width: 100%;
  height: 570px;
  display: flex;
  margin-top: 30px;
}

.banners__column {
  flex: 0 0 42%;

  &--slider {
    flex-basis: 58%;
    overflow: hidden;
  }
}

.banners__small {
  padding: 34px 30px 0;
  box-sizing: border-box;
  position: relative;
  text-transform: uppercase;

  a:hover {
    text-decoration: none;
  }

  &--top {
    height: 56%;
    background: url('../img/banners/banner-small-top.png') 100% 50% no-repeat,
                linear-gradient(58deg, #ededef, #e0e3e2);                ;

    .banners__category,
    .banners__slogan {
      color: #686868;
    }

    .banners__link {
      color: #1882f7;
    }

    .banners__svg {
      fill: #1882f7;
    }
  }

  &--bottom {
    height: 44%;
    background: url('../img/banners/banner-small-bot.png') 115% 150% no-repeat,
                linear-gradient(116deg, #605b55, #444447);
    background-size: auto, auto;

    .banners__category,
    .banners__slogan,
    .banners__link {
      color: #fff;
    }

    .banners__svg {
      fill: #fff;
    }
  }
}

.banners__category {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.3px;
  margin-bottom: 19px;
}

.banners__slogan {
  font-size: 24px;
  line-height: 32px;
}

.banners__link {
  position: absolute;
  left: 30px;
  bottom: 50px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.banners__svg {
  margin-left: 14px;
}

.banners__slider {
  height: 100%;
  width: 100%;
  position: relative;

  a:hover {
    text-decoration: none;
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: auto;
    bottom: 55px;
  }

  .swiper-button-next {
    right: 100px;
  }

  .swiper-button-prev {
    left: 482px;
  }

  .swiper-pagination {
    text-align: left;
    left: 60px !important;
    bottom: 60px !important;
    display: flex;
    align-items: center;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #d8d8d8;
    opacity: 1;
    margin: 0 10px !important;
  }

  .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    background: radial-gradient(circle, #ffffff 0%, #ffffff 40%, #000000 40%, #000000 100%);
    border: 1px solid #ffffff;
  }

  .banners__link {
    position: static;
    color: #fff;
  }

  .banners__svg {
    fill: #ffffff;
  }
}

.banners__title {
  font-size: 40px;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.banners__slider-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 70px 67px 0;
}

@media screen and (max-width: 768px) {
  .banners {
    height: 330px;
    margin-top: 30px;
  }

  .banners__title {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 30px;
  }

  .banners__link {
    font-size: 12px;
    line-height: 12px;
    bottom: 30px;
  }

  .banners__slider-content {
    padding: 39px 30px;
  }

  .banners__slider {
    .swiper-pagination {
      display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
      bottom: 30px !important;
      width: 12px !important;
      height: 20px !important;
      background-size: 100%;

    }

    .swiper-button-next {
      right: 20px;
    }

    .swiper-button-prev {
      right: 60px;
      left: auto;
    }
  }

  .banners__small {
    padding: 30px;
    background-size: auto, auto;

    &--bottom {
      padding: 18px 30px;
      background-size: 120px auto, auto;
      background-position: 110% 150%;
    }
  }

  .banners__slogan {
    font-size: 14px;
    line-height: 20px;
  }

  .banners__category {
    font-size: 12px;
    line-height: normal;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 520px) {
  .banners {
    display: block;
    height: auto;
    margin-top: 50px;
  }

  .banners__column {
    width: 100%;

    &--slider {
      width: 100%;
    }
  }

  .banners__title {
    font-size: 24px;
  }

  .banners__slider-content {
    padding: 20px;
  }

  .banners__slider {
    height: 247px;

    .swiper-pagination {
      display: block;
      bottom: 15px !important;
      left: 15px !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
      bottom: 15px !important;
      width: 12px !important;
      height: 20px !important;
      background-size: 100%;

    }

    .swiper-button-next {
      right: 20px;
    }

    .swiper-button-prev {
      right: 60px;
      left: auto;
    }
  }

  .banners__small {
    padding: 17px 20px;

    .banners__slogan {
      font-size: 18px;
      line-height: 24px;
    }

    &--top {
      min-height: 185px;
    }

    &--bottom {
      min-height: 170px;
      background-size: 125px auto, auto;
      background-position: 120% 50%;
    }
  }

  .banners__link {
    left: 20px;
    bottom: 20px;
  }
}