.header {
  box-shadow: 0 10px 20px 0 rgba(40, 62, 85, 0.1);
  height: 101px;
  background-color: #ffffff;

  .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header__column {
  &--mobile {
    display: none;
  }
}

.header__input {
  width: 270px;
  height: 40px;
  margin: 0 auto;
  display: flex;

  .input {
    border-right-color: transparent;
    width: 230px;
  }
}

.header__search {
  background-color: #1882f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
}

.header__contacts {
  display: flex;
  align-items: center;
}

.header__link {
  font-size: 12px;
  font-weight: 500;
  color: #191919;
  opacity: 0.6;
  text-decoration: underline;

  &--phone {
    color: #0a0a0a;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    opacity: 1;
    text-transform: uppercase;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.header__phone {
  margin-right: 31px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  text-transform: uppercase;
}

.header__address {
  font-size: 14px;
  line-height: 15px;
  font-weight: 800;
}

.header__label {
  display: none;
}

@media screen and (max-width: 768px) {
  .header__address {
    display: none;
  }

  .header__logo {
    width: 128px;
  }
}

@media screen and (max-width: 520px) {
  .header {
    position: fixed;
    height: 50px;
    padding: 6px 0;
    width: 100%;
    z-index: 10000;
    top: 0;
  }

  .header__column {
    &--mobile {
      display: block;
    }

    &--desktop {
      display: none;
    }

    &--cart {
      position: relative;
    }
  }

  .header__label {
    display: block;
    font-size: 8px;
    height: 14px;
    width: 14px;
    line-height: 14px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background-color: #f45958;
    position: absolute;
    top: -4px;
    right: -6px;
  }

  .header__burger {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    padding: 5px;
    position: relative;
  }

  .header__burger-line {
    position: absolute;
    width: 18px;
    height: 2px;
    background-color: #0a0a0a;
    top: calc(50% - 1px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 2px;
      background-color: #0a0a0a;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }

    &--open {
      background-color: transparent;

      &::before {
        top: 0;
        transform: rotate(-45deg);
      }
      &::after {
        bottom: 0;
        transform: rotate(45deg);
      }
    }
  }

  .header__input {
    width: 90%;
    margin: 0;

    .input {
      width: calc(100% - 40px);
    }
  }
}