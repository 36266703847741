.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  background-color: rgba(48,48,48,.6);
  display: none;
}