.catalog-wrapper {
  margin-top: 60px;
  display: flex;
}

.catalog {
  width: 100%;
  box-sizing: border-box;

  &--promo {
    .pagination {
      display: none;
    }
  }
}

.catalog__title {
  color: #686868;
  font-size: 14px;
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;

  &--mobile {
    display: none;
  }


  .select {
    margin-left: 40px;
  }
}

.catalog__list {
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &--catalog {
    .catalog__item {
      &:not(:nth-child(3n + 3)) {
        margin-right: 30px;
      }
    }
  }

  &--promo {
    .catalog__item {
      margin-right: 30px;

      &:nth-child(4n+4) {
        margin-right: 0;
      }
    }
  }
}

.catalog__item {
  width: 270px;
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;

  .button {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: #1882f7;
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #1882f7;
      color: #fff;
      cursor: pointer;
    }
  }

  &:hover {
    box-shadow: 0 15px 30px 0 rgba(10, 10, 10, 0.06);

    .catalog__info {
      border-color: #676767;
      border-bottom-color: transparent;
    }
  }
}

.catalog__info {
  box-sizing: border-box;
  border: 1px solid #979797;
  padding-top: 35px;
  position: relative;
  border-bottom-color: transparent;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.catalog__img {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.catalog__name {
  display: block;
  color: #0a0a0a;
  font-size: 14px;
  line-height: 18px;
  margin: 2px auto auto;
  padding: 0 5px;

  &:hover {
    color: #1882f7;
  }
}

.catalog__price {
  margin: 30px auto 18px;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  color: #0a0a0a;

  &--new {
    margin-top: 4px;
  }

  &--old {
    margin: 8px auto 4px;
    font-size: 14px;
    color: #f45958;
    text-decoration: line-through;
  }
}

@media screen and (max-width: 768px) {
  .catalog {
    &--promo {
      position: relative;

      .pagination {
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        margin: 0;
      }

      .pagination__arrow {
        margin-left: 15px;
      }
    }
  }

  .catalog__list {
    &--catalog {
      padding-left: 0;

      .catalog__item {
        &:nth-child(2n + 2) {
          margin-right: 0;
        }

        &:nth-child(3n + 3) {
          margin-right: 0;
        }

        &:nth-child(odd) {
          margin-right: 30px;
        }
      }
    }

    &--promo {
      .catalog__item {
        margin-bottom: 30px;

        &:not(:last-child) {
          margin-right: 0;
        }

        &:not(:nth-child(3n + 3)) {
          margin-right: 29px;
        }
      }
    }
  }

  .catalog__item {
    width: 206px;
    display: flex;
    flex-direction: column;

    .button {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .catalog__title {
    align-items: flex-start;
  }

  .catalog__sort {
    span {
      display: block;
    }

    .select {
      margin-left: 0;
      margin-top: 7px;
    }
  }

  .catalog__img {
    width: 154px;
    height: 154px;
  }
}

@media screen and (max-width: 520px) {
  .catalog-wrapper {
    display: block;
    margin-top: 15px;
  }

  .catalog__list {
    &--promo {
      padding-left: 0;
    }

    .catalog__item {
      flex: 0 0 45%;

      &:nth-child(n + 1) {
        margin-right: 10%;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

      .button {
        font-size: 9px;
        margin-bottom: 0;
      }
    }
  }

  .catalog__img {
    width: 100%;
    height: auto;
  }

  .catalog__title {
    &--mobile {
      display: flex;
      align-items: center;
    }

    &--desktop {
      display: none;
    }

    .select {
      padding: 0 38px 0 16px;
      font-size: 14px;
    }
  }

  .catalog__sort {
    flex: 0 0 50%;

    .select {
      margin-top: 0;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .catalog__filter {
    flex: 0 0 50%;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
    height: 40px;
    line-height: 40px;

    .filter__title {
      text-align: center;
      padding: 0;

      &::before {
        top: 10px;
        left: 20px;
      }
    }
  }
}

