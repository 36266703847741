.menu {
  display: none;
}

@media screen and (max-width: 520px) {
  .menu {
    display: block;
    position: fixed;
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    top: 62px;
    box-sizing: border-box;
    padding: 15px 15px 30px;
    left: -100%;
    transition: left .3s;
    z-index: 100;

    .button {
      line-height: 50px;
      width: 90%;
      font-size: 16px;
      margin: 0 0 30px;
    }

    &--open {
      left: 0;
    }
  }

  .menu__scroll {
    overflow-x: hidden;
    overflow-y: auto;
    width: 107%;
    height: 100%;
  }

  .menu__wrapper {
    overflow-x: hidden;
    width: 105%;
    height: 100%;
  }

  .menu__list {
    list-style: none;
    width: 90%;
    padding: 0;
    margin: 0;
  }

  .menu__link {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin: 30px 0;
    text-transform: uppercase;
    color: #686868;
    position: relative;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-image: url('../img/icons/arrow-down.svg');

    &--open {
      margin-bottom: 15px;
      color: #0a0a0a;
      background-image: url('../img/icons/arrow-top.svg');
    }

    &--index {
      background: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .menu__svg {
    margin-right: 5px;
    vertical-align: text-top;
  }

  .submenu {
    border-top: 1px solid #dfdfdf;
    list-style: none;
    margin: 0;
    padding-left: 20px;
    display: none;
  }

  .submenu__link {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #686868;
    margin: 20px 0;

    &:hover {
      text-decoration: none;
    }
  }
}