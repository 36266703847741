@import url('https://fonts.googleapis.com/css?family=Lato|Playfair+Display:400,400i');

body {
  background-color: #fff;
  font-family: 'TT Norms', sans-serif;
  color: #000;
}

a {
  font-family: 'TT Norms', sans-serif;
  line-height: normal;
  letter-spacing: normal;
  transition: all .3s;
  text-decoration: none;
  color: #686868;

  &:hover {
    text-decoration: underline;
  }
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container {
  width: 1170px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .container {
    width: 678px;
  }
}

@media screen and (max-width: 520px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
  }
}
