.cart__header {
  display: flex;
  align-items: center;
}

.cart__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #0a0a0a;
}

.cart__return {
  font-size: 14px;
  line-height: 24px;
  margin-left: 30px;
  letter-spacing: 0.1px;
  color: #686868;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.cart__table-title {
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #686868;
  padding: 10px 0;
  border-bottom: 1px solid #dfdfdf;

  .cart__table-column {
    flex: 0 0 20%;

    &:first-child {
      flex-basis: 40%;
    }
  }
}

.cart__table-row {
  display: flex;
  align-items: center;
  padding: 15px 0;
  position: relative;

  &:nth-child(2) {
    padding-top: 30px;
  }

  &:last-child {
    padding-bottom: 30px;
  }
}

.cart__img {
  flex: 0 0 10%;
  margin-right: 3%;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #dfdfdf;
  display: inline-block;

  img {
    height: 100%;
  }
}

.cart__info {
  flex: 0 0 87%;
  display: flex;
  align-items: center;
}

.cart__name {
  flex: 0 0 31%;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: #0a0a0a;
}

.cart__numbers {
  flex: 0 0 69%;
  display: flex;
  align-items: center;
}

.cart__price, .cart__sum {
  font-size: 18px;
  font-weight: 700;
  color: #0a0a0a;
}

.cart__counter {
  .counter  {
    width: 90px;
  }
}

.cart__price, .cart__counter, .cart__sum {
  flex: 0 0 33.3333%;
}

.cart__table {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
}

.cart__delete {
  position: absolute;
  right: 0;
  color: #979797;
  font-size: 28px;
  background: none;
  padding: 0;
  border: none;

  &:hover {
    text-decoration: none;
  }
}

.cart__total-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
}

.cart__coupon {
  padding: 25px 0 33px;
}

.cart__coupon-title {
  font-size: 14px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}

.cart__coupon-input {
  display: inline-flex;
  margin-right: 30px;

  .input {
    width: 230px;
    border-right-color: transparent;
  }
}

.cart__input-arrow {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #1882f7;
  border: none
}

.cart__coupon-success {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #30a780;
  text-transform: uppercase;

  &::before {
    content: '✓';
    font-size: 20px;
    margin-right: 7px;
  }
}

.cart__coupon-error {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #ff0000;
  text-transform: uppercase;
}

.cart__coupon-error-svg {
  margin-right: 7px;
}

.cart__total {
  width: 270px;
  height: 130px;
  box-sizing: border-box;
  background-color: #f9f9fa;
  padding: 35px 0 0 45px;
}

.cart__total-title {
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  margin-bottom: 5px;
}

.cart__total-price {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: normal;
  color: #0a0a0a;
}

.cart__button-wrapper {
  padding: 30px 0;
  border-bottom: 1px solid #dfdfdf;

  .button {
    width: 270px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-left: auto;
  }
}

.cart__checkout {
  padding: 0 0 38px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cart__checkout-form {
  padding-top: 20px;
}

.cart__checkout-total {
  box-sizing: border-box;
  width: 370px;
  padding: 20px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  border: 2px solid #dfdfdf;
  background-color: #ffffff;

  .button {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    width: 100%;
  }
}

.cart__checkout-title {
  font-size: 22px;
  font-weight: 700;
  color: #45443c;
  margin-bottom: 18px;
}

.cart__checkout-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 11px;
}

.cart__checkout-column {
  font-size: 18px;
  color: #686868;

  span {
    font-weight: 700;
    color: #0a0a0a;
  }

  &--dots {
    width: 100%;
    border-bottom: 1px dotted #686868;
  }
}

.cart__checkout-text {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #686868;
}

.cart__checkout-sum {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: #0a0a0a;
  margin-bottom: 17px;
}

.cart__row {
  margin-bottom: 10px;

  &--top,
  &--radio {
    display: flex;
    align-items: flex-start;
  }

  &--radio {
    margin: 20px 0 0;
  }
}

.cart__label {
  width: 180px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #0a0a0a;
  display: inline-block;

  &--textarea {
    padding-left: 0;
    display: inline-block;
    width: 180px;
  }
}

.cart__star {
  color: #ff0000;
}

.cart__input {
  width: 570px;
  height: 50px;
  padding: 0 15px;
  border: 1px solid #d8d8d8;
  color: #c6c6c6;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border-color: #0a0a0a;
    color: #000000;
  }

  &:valid {
    border-color: #30a780;
  }

  &:not(:focus):required:invalid {
    border: 2px solid #ff0000;
  }
}

.cart__require {
  display: none;
  font-size: 14px;
  color: #ff0000;
  margin: 10px 0 10px 180px;
}

.cart__input:invalid + .cart__require {
  display: block
}

.cart__textarea {
  width: 570px;
  height: 170px;
  padding: 15px;
  border: 1px solid #d8d8d8;
  color: #c6c6c6;
  outline: none;
  resize: none;
  box-sizing: border-box;

  &:focus {
    border-color: #0a0a0a;
    color: #000000;
  }
}

.cart__result {
  border: 2px solid #dfdfdf;
  padding: 38px 0 46px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;

  &--success {
    color: #30a780;
  }

  &--fail {
    color: #f45958;
  }
}

.cart__result-img {
  font-size: 100px;
  margin-bottom: 12px;
}

.cart__result-text {
  margin-bottom: 20px;
}

.cart__result-link {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #686868;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.cart__radio-wrapper {
  padding-top: 2px;
}

.cart__radio-item {
  margin-bottom: 20px;
}

.cart__radio {
  display: none;

  &:checked + .cart__label-radio::before {
    border: 4px solid #fff;
    background-color: #000;
  }
}

.cart__label-radio {
  font-size: 16px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border-radius: 100%;
    box-shadow: 0 0 1px 1px #000;
    background-color: #fff;
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .cart__title {
    font-size: 36px;
    line-height: 48px;
  }

  .cart__checkout {
    display: block;
  }

  .cart__table-title {
    .cart__table-column {
      flex: 0 0 20%;

      &:first-child {
        flex-basis: 45%;
      }
    }
  }

  .cart__total {
    height: 153px;
  }

  .cart__row {
    &--top {
      display: block;
    }
  }

  .cart__label {
    width: 118px;
    padding-left: 0;
    margin-bottom: 5px;

    &--textarea {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .cart__label {
    width: 114px;
    padding-left: 0;
  }

  .cart__input {
    width: 560px;
  }

  .cart__require {
    margin-left: 118px;
  }

  .cart__textarea {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 520px) {
  .cart__title {
    font-size: 24px;
    line-height: 28px;
  }

  .cart__table-title {
    display: none;
  }

  .cart__table-row {
    align-items: flex-start;

    &:nth-child(2) {
      .cart__delete {
        top: 15px;
      }
    }
  }

  .cart__info {
    display: block;
    margin-bottom: 0;
    flex: 0 0 67%;
  }

  .cart__header {
    justify-content: space-between;
  }

  .cart__return {
    font-size: 12px;
    line-height: normal;
    margin-left: 0;
  }

  .cart__numbers {
    justify-content: space-between;
    width: 100%;

    .counter {
      height: 30px;
    }
  }

  .cart__price {
    font-size: 14px;
    line-height: 14px;
  }

  .cart__name {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 15px;
  }

  .cart__sum {
    display: none;
  }

  .cart__delete {
    top: 0;
  }

  .cart__coupon-input {
    width: 100%;
    margin-right: 0;

    .input {
      width: calc(100% - 40px);
    }
  }

  .cart__total-wrapper {
    display: block;
  }

  .cart__total {
    width: 100%;
    text-align: center;
    height: auto;
    padding: 25px 0 30px;
  }

  .cart__total-title {
    font-size: 16px;
    padding-left: 0;
  }

  .cart__button-wrapper {
    .button {
      width: 100%;
    }
  }

  .cart__row {
    margin-bottom: 15px;

    &--radio {
      display: block;

      .cart__label {
        width: auto;
      }
    }
  }

  .cart__input {
    width: 100%;
    box-sizing: border-box;
  }

  .cart__require,
  .cart__radio-wrapper {
    margin-left: 0;
  }

  .cart__checkout {
    display: block;
    padding-bottom: 0;
  }

  .cart__checkout-total {
    width: 100%;
  }

  .cart__img {
    flex: 0 0 30%;
    height: 73px;
  }
}

@media screen and (min-width: 768px) {
  .cart__delete {
    margin-top: -5px;
  }
}