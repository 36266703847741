.input {
  border: 2px solid #dfdfdf;
  font-family: 'TT Norms', sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e8e93;
  padding: 0 14px;
  box-sizing: border-box;
  background-color: transparent;
}

.title {
  color: #0a0a0a;
  font-family: 'TT Norms', sans-serif;
  font-weight: 700;
  letter-spacing: normal;
  margin: 12px 0;

  &--h1 {
    font-size: 40px;
    line-height: 48px;
  }

  &--h2 {
    font-size: 36px;
    line-height: 44px;
  }

  &--h3 {
    font-size: 24px;
    line-height: 28px;
  }

  &--h4 {
    font-size: 20px;
    line-height: 24px;
  }

  &--h5 {
    font-size: 18px;
    line-height: 20px;
  }

  &--h6 {
    font-size: 16px;
    line-height: 18px;
  }

  &--catalog {
    font-size: 40px;
    line-height: 48px;
  }
}

.text {
  color: #0a0a0a;
  font-size: 14px;
  line-height: 18px;
  margin: 20px 0;
}

.column {
  column-gap: 30px;
  margin: 30px 0;

  &--two {
    column-count: 2;
  }

  &--three {
    column-count: 3;
  }

  &--four {
    column-count: 4;
  }

  .text {
    margin: 0 0 20px;
  }
}

.blockquote {
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  line-height: 29px;
  letter-spacing: normal;
  color: #686868;
  padding-left: 77px;
  margin: 38px 0 53px;
  display: flex;
  justify-content: center;

  &::before {
    content: '«';
    color: #1882f7;
    font-size: 64px;
    font-style: normal;
    margin-right: 15px;
    margin-top: -5px;
  }

  &::after {
    content: '»';
    color: #1882f7;
    font-size: 64px;
    font-style: normal;
    align-self: flex-end;
  }
}

.list {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: normal;
  color: #0a0a0a;
  column-count: 2;

  &--numbered {
    column-count: 1;
    line-height: 20px;
    list-style-type: none;
    counter-reset: item;

    .list__item {
      &::before {
        content: counter(item) '.';
        counter-increment: item;
        font-weight: 700;
        display: inline;
        background-color: transparent;
      }
    }
  }
}

.list__item {
  list-style: none;

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #1882f7;
    margin-right: 10px;
  }
}

.img {
  width: 100%;
  display: block;

  &--right {
    width: auto;
    float: right;
    margin-left: 30px;
    margin-bottom: 30px;
  }

  &--left {
    width: auto;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

.table {
  width: 100%;
  font-size: 16px;
  color: #000;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0;

  tbody tr {
    &:nth-child(odd) {
      td {
        background-color: #f9fbfc;
      }
    }
  }

  th {
    background-color: #fff;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }

  td, th {
    height: 54px;
    padding: 0 15px;
  }
}

.checkbox {
  display: none;
}

.label {
  font-size: 16px;
  color: #0a0a0a;
  padding-left: 30px;
  position: relative;

  &--checkbox {
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      box-sizing: border-box;
      border: 1px solid #0a0a0a;
      vertical-align: text-bottom;
      position: absolute;
      left: 0;
      top: -2px;
    }

    &::after {
      display: none;
    }
  }

  &--white {
    font-size: 12px;
    color: #fff;
    color: rgba(255, 255, 255, 0.54);

    a {
      color: #fff;

    }
    &::before {
      border-color: #fff;
    }
  }
}

.checkbox {
  &:checked + .label--checkbox {
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #0a0a0a;
      position: absolute;
      left: 6px;
      top: 4px;
      display: block;
    }
  }

  &--white {
    &:checked + .label--checkbox {
      &::after {
        background-color: #ffffff;
      }
    }
  }
}

.button {
  height: 50px;
  line-height: normal;
  text-align: center;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  color: #1882f7;
  border: 1px solid #1882f7;
  background-color: transparent;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: #1882f7;
    color: #fff;
  }

  &--blue {
    background-color: #1882f7;
    color: #fff;

    &:hover {
      background-color: transparent;
      border-color: #1882f7;
      color: #1882f7;
    }
  }

  &--white {
    background-color: #edeef4;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    border-color: transparent;
    color: #0a0a0a;
  }
}

.select {
  border: 1px solid #d8d8d8;
  width: 142px;
  padding: 0 19px 0;
  box-sizing: border-box;
  height: 40px;
  font-size: 16px;
  color: #686868;
  font-weight: 700;
  background-color: #fff;
  appearance: none;
  position: relative;
  background: url('../img/icons/arrow-down.svg') calc(100% - 20px) 50% no-repeat;;
}

.select__option {
  padding: 10px 19px;
  font-size: 16px;
  color: #686868;
  font-weight: 700;
  line-height: 36px;
}

@media screen and (max-width: 768px) {
  .blockquote {
    padding-left: 0;
  }

  .button {
    font-size: 12px;
    line-height: 14px;
  }

  .title {
    &--h1 {
      font-size: 36px;
      line-height: 48px;
    }

    &--h2 {
      font-size: 28px;
      line-height: 44px;
    }

    &--h3 {
      font-size: 24px;
      line-height: normal;
    }

    &--h4 {
      font-size: 20px;
      line-height: 24px;
    }

    &--h5 {
      font-size: 18px;
      line-height: 20px;
    }

    &--h6 {
      font-size: 16px;
      line-height: 18px;
    }

    &--catalog {
      font-size: 36px;
      line-height: 48px;
    }
  }
}

@media screen and (max-width: 520px) {
  .title {
    &--h1 {
      font-size: 32px;
      line-height: 36px;
    }

    &--h2 {
      font-size: 24px;
      line-height: 44px;
    }

    &--h3 {
      font-size: 24px;
      line-height: normal;
    }

    &--h4 {
      font-size: 18px;
      line-height: 24px;
    }

    &--h5 {
      font-size: 16px;
      line-height: 20px;
    }

    &--h6 {
      font-size: 16px;
      line-height: 18px;
    }

    &--catalog {
      font-size: 28px;
      line-height: 28px;
    }
  }

  .blockquote {
    padding: 0;
    color: #0a0a0a;
    font-size: 16px;
    line-height: 20px;
    display: block;

    &::before {
      margin-bottom: 10px;
    }

    &::before, &::after {
      display: block;
      font-size: 46px;
    }

    &::after {
      text-align: right;
    }
  }

  .list {
    padding: 0;
  }

  .img {
    width: 100%;
    margin: 20px 0;

    &--left, &--right {
      float: none;
      width: 100%;
      margin: 20px 0;
    }
  }

  .column {
    column-gap: 0;

    &--two, &--three, &--four {
      column-count: 1;
    }
  }
}
