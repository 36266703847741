.tag__list {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
}

.tag__item {
  display: inline-block;
  width: 50px;
  height: 25px;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;

  &--new {
    box-shadow: 0 2px 4px 0 rgba(48, 167, 128, 0.5);
    background-color: #30a780;
  }

  &--top {
    box-shadow: 0 2px 4px 0 rgba(244, 89, 88, 0.5);
    background-color: #f45958;
  }

  &--sale {
    box-shadow: 0 2px 4px 0 rgba(254, 189, 118, 0.5);
    background-color: #febd76;
    color: #0a0a0a;
  }

  &:not(:last-child) {
    margin-right: 4px;
  }
}

@media screen and (max-width: 520px) {
  .tag__list {
    right: 5px;
    top: 5px
  }

  .tag__item {
    width: 36px;
    height: 20px;
    font-size: 11px;
    line-height: 20px;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}