.news__list {
  list-style: none;
  padding: 0;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
}

.news__item {
  font-size: 14px;
  width: 270px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  .button {
    line-height: 50px;
    text-transform: uppercase;
    font-weight: 700;
    width: 206px;

    &:hover {
      background-color: #1882f7;
      color: #fff;
      text-decoration: none;

      .news__svg-arrow {
        fill: #fff;
      }
    }
  }
}

.news__title {
  margin: 15px 0 10px;
  color: #0a0a0a;
  font-weight: 700;
}

.news__intro {
  display: block;
  color: #0a0a0a;
  margin: 10px 0 15px;

  &:hover {
    color: #1882f7;
  }
}

.news__date {
  color: #bcbdbc;
}

.news__more {
  font-size: 18px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}

.news__svg {
  margin-left: 7px;
}

.news__svg-arrow {
  fill: #1882f7;
  transition: all .3s;
}

@media screen and (max-width: 768px) {
  .news__list {
    margin: 30px 0 0;
  }

  .news__item {
    width: 206px;
    margin-bottom: 30px;

    &:not(:last-child) {
      margin-right: 0;
    }

    &:not(:nth-child(3n + 3)) {
      margin-right: 29px;
    }
  }
}

@media screen and (max-width: 520px) {
  .news {
    .container {
      padding: 0;
    }
  }
  .news__list {
    display: block;
  }

  .news__item {
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    .button {
      width: 100%;
    }
  }

  .news__more {
    font-size: 24px;
    line-height: 28px;
  }
}