.counter {
  height: 40px;
  width: 80px;
  padding: 10px 17px;
  border: 1px solid #d8d8d8;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
}

.counter__link {
  font-size: 18px;
  line-height: 40px;
  color: #686868;
  background-color: transparent;
  border-color: transparent;
  outline: none;

  &:hover {
    text-decoration: none;
  }
}

.counter__count {
  font-size: 14px;
  height: 100%;
  color: #0a0a0a;
  border: none;
  text-align: center;
  width: 30px;
  max-width: 50px;
}

@media screen and (max-width: 520px) {
  .counter {
    width: 90%;
  }
}

