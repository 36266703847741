.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 61px auto 85px;
}

.pagination__link {
  font-size: 12px;
  text-decoration: underline;
  color: #45443c;

  &:hover {
    text-decoration: none;
  }

  &--disabled {
    color: #d8d8d8;
    pointer-events: none;
  }

  &--start {
    margin-right: 27px;
  }

  &--end {
    margin-left: 27px;
  }
}

.pagination__list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.pagination__arrow {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  border: 1px solid #1882f7;
  background-color: #1882f7;

  .pagination__svg {
    fill: #ffffff;
  }

  &:hover, &--active {
    background-color: transparent;

    .pagination__svg {
      fill: #45443C;
    }
  }
}

.pagination__item {
  line-height: 30px;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 10px;

  a {
    color: #d8d8d8;
  }

  &--active {
    a {
      color: #0a0a0a;
    }
  }
}

@media screen and (max-width: 768px) {
  .pagination {
    margin: 30px auto 60px;
  }
}