.filter {
  flex: 0 0 270px;
  margin-right: 30px;
}

.filter__section {
  padding: 18px 0 18px 28px;

  &:not(&--color) {
    border-bottom: 2px solid #e2e2e2;
  }
}

.filter__title {
  color: #686868;
  font-size: 14px;
  position: relative;
  padding-left: 30px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    background: url('../img/icons/filter.png') 50% 50% no-repeat;
    background-size: 20px 20px;
    opacity: .5;
  }
}

.filter__section-title {
  font-size: 18px;
  font-weight: 700;
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: url('../img/icons/arrow-down.svg');
  }

  &--price,
  &--color {
    margin-bottom: 20px;

    &::after {
      display: none;
    }
  }

  &--open {
    &::after {
      transform: rotate(-180deg);
    }

    & + .filter__content {
      display: block;
    }
  }
}

.filter__content {
  display: none;
  margin-top: 20px;
}

.filter__list {
  list-style: none;
  padding: 0;
  margin: 0 0 0 30px;
}

.filter__item {
  margin: 11px 0;
  font-size: 16px;
}

.filter__link {
  color: #0a0a0a;

  &--active, &:hover {
    color: #1882f7;
  }
}

.filter__row {
  margin-bottom: 10px;
}

.filter__color-list {
  list-style: none;
  padding: 0;
  margin: 18px auto;
  width: 141px;
  display: flex;
  flex-wrap: wrap;
}

.filter__color-item {
  margin-bottom: 15px;
  width: 24px;
  height: 24px;

  &:not(:nth-child(4n + 4)) {
    margin-right: 15px;
  }
}

.filter__color-checkbox {
  display: none
}

.filter__color-label {
  font-size: 0;
  display: block;
  height: 100%;
  box-sizing: border-box;
  border-color: transparent;

  &--red {
    background-color: #d0021b;
  }

  &--orange {
    background-color: #f5a623;
  }

  &--green {
    background-color: #7ed321;
  }

  &--blue {
    background-color: #4a90e2;
  }

  &--magenta {
    background-color: #bd10e0;
  }

  &--violet {
    background-color: #9013fe;
  }

  &--cyan {
    background-color: #50e3c2;
  }

  &--navy {
    background-color: #008eff;
  }
}

.filter__color-checkbox:checked + .filter__color-label {
  border: 1px solid #0a0a0a;
}

.filter__button-group {
  display: flex;

  .button {
    width: 50%;
  }
}

.filter__close {
  display: none;
}

@media screen and (max-width: 768px) {
  .filter {
    flex-basis: 206px;
  }

  .filter__section {
    padding-left: 0;
  }
}

@media screen and (max-width: 520px) {
  .filter {
    position: fixed;
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    top: 62px;
    z-index: 1000;
    box-sizing: border-box;
    padding: 15px 15px 30px;
    left: -100%;
    transition: left .3s;

    &--open {
      left: 0;
    }
  }

  .filter__scroll {
    overflow-x: hidden;
    overflow-y: auto;
    width: 107%;
    height: 100%;
  }

  .filter__wrapper {
    overflow-x: hidden;
    width: 105%;
    height: 100%;
  }

  .filter__container {
    width: 90%;
    padding-bottom: 30px;
  }

  .filter__section {
    &--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .filter__close {
    display: block;
    font-size: 26px;
  }
}

.filter__range-wrapper {
  .noUi-target {
    background: #6c6c6c;
    border-radius: 0;
    border: 1px solid #6c6c6c;
    box-shadow: none;
    height: 1px;
  }

  .noUi-handle {
    width: 8px;
    height: 12px;
    background-color: #0a0a0a;
    box-shadow: none;
    border: none;
    border-radius: 0;

    &::before,
    &::after {
      display: none;
    }
  }
}

.filter__range-sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px -4px 0;
}

.filter__range-input {
  width: 100px;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #0a0a0a;

  &--to {
    text-align: right;
  }
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -4px;
}