.gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;

  &--two {
    .gallery__link {
      flex-basis: 48%;
    }
  }

  &--three {
    .gallery__link {
      flex-basis: 31%;
    }
  }

  &--four {
    .gallery__link {
      flex-basis: 23%;
    }
  }
}

.gallery__link {
  display: inline-block;
  margin-bottom: 15px;
}

@media screen and (max-width: 520px) {
  .gallery {
    &--two,
    &--three,
    &--four {
      .gallery__link {
        flex-basis: 100%;
      }
    }
  }
}