.about {
  display: flex;
  margin: 10px 0 50px;
  align-items: center;
}

.about__text {
  flex: 0 0 69%;
  width: 69%;
  box-sizing: border-box;
  padding-right: 40px;
  border-right: 1px solid #dfdfdf;
  margin-right: 67px;
}

@media screen and (max-width: 768px) {
  .about {
    align-items: flex-start;
  }

  .about__text {
    padding-right: 14px;
    margin-right: 14px;
  }
}

@media screen and (max-width: 520px) {
  .about {
    display: block;
  }

  .about__text {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }

  .about__img {
    text-align: center;
    .img {
      width: auto;
      margin: 0 auto;
    }
  }
}