.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 44px;
  border-bottom: 1px solid #979797;
  border-top: 1px solid #979797;
}

.footer__list {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: flex-start;
  padding: 0;
}

.footer__item {
  max-width: 171px;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.footer__title {
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #686868;
}

.footer__link {
  font-size: 14px;
  line-height: 20px;
  color: #686868;
}

.footer__copyright {
  padding: 17px 0 22px;
  font-size: 12px;
  line-height: 15px;
  font-weight: normal;
  letter-spacing: normal;
  color: #686868;
  display: flex;
  justify-content: space-between;

  a {
    color: #686868;
  }
}

.footer__span {
  text-transform: uppercase;
}

.footer__social-text {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #0a0a0a;
  text-transform: uppercase;
}

.footer__social-number {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 30px;
  color: #0a0a0a;
  letter-spacing: -1px;
  display: block;
}

.footer__social-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.footer__social-item {
  flex: 0 0 16px;
  height: 16px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  a {
    font-size: 0;
    display: block;
    height: 100%;
  }

  &--vk {
    flex: 0 0 22px;
    height: 12px;
    background-image: url("../img/icons/vk.svg");
  }

  &--fb {
    background-image: url("../img/icons/fb.svg");
  }

  &--inst {
    background-image: url("../img/icons/instagram.svg");
  }

  &:not(:last-child) {
    margin-right: 40px;
  }
}

@media screen and (max-width: 768px) {
  .footer__container {
    display: block;
  }

  .footer__list {
    margin-bottom: 40px;
    padding-right: 40px;
    justify-content: space-between;
  }

  .footer__social {
    text-align: right;
  }

  .footer__social-list {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 520px) {
  .footer__list {
    flex-wrap: wrap;
  }

  .footer__item {
    flex: 0 0 45%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &:not(:nth-child(2n + 2)) {
      margin-right: 5%;
    }
  }

  .footer__title {
    margin-bottom: 15px;
  }

  .footer__link {
    font-size: 12px;
    line-height: normal;
    display: inline-block;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .footer__social {
    text-align: left;
  }

  .footer__social-list {
    justify-content: space-between;
  }

  .footer__social-item {
    flex: 0 0 32px;
    height: 32px;
  }

  .footer__social-text {
    margin-bottom: 0;
  }
}