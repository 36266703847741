.subscribe {
  background-color: #000;
  padding: 54px 70px;
  display: flex;
  justify-content: space-between;
  margin: 65px 0 57px;
}

.subscribe__column {
  width: 50%;
}

.subscribe__title {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 3px;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.subscribe__text {
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  color: #ffffff;
}

.subscribe__input {
  display: flex;
  margin-bottom: 10px;

  .input {
    height: 50px;
    opacity: 0.54;
    font-size: 14px;
    color: #ffffff;
    width: 290px;
    text-transform: uppercase;
  }

  .button {
    width: 170px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 768px) {
  .subscribe {
    margin: 30px 0 35px;
  }
  .subscribe__text {
    font-size: 14px;
    line-height: 18px;
  }

  .subscribe__title {
    font-size: 24px;
    margin-bottom: 33px;
  }

  .subscribe {
    padding: 42px 30px;
    align-items: center;
  }

  .subscribe__input {
    .input {
      width: 155px;
    }
  }
}

@media screen and (max-width: 520px) {
  .subscribe {
    display: block;
  }

  .subscribe__column {
    width: 100%;
  }

  .subscribe__title {
    margin-bottom: 15px;
  }

  .subscribe__text {
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 14px;
  }

  .subscribe__input {
    display: block;
    margin-bottom: 15px;

    .input {
      width: 100%;
      height: 50px;
    }

    .button {
      width: 100%;
    }
  }
}