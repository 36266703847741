.breadcrumbs__list {
  list-style: none;
  margin: 0;
  padding: 27px 0 20px;
  font-size: 14px;
  color: #686868;
}

.breadcrumbs__item {
  display: inline;

  &:not(:last-child)::after {
    content: ' / ';
    display: inline;
  }
}

.breadcrumbs__link {
  color: inherit;
}

@media screen and (max-width: 520px) {
  .breadcrumbs {
    padding-top: 50px;
  }
}